<template>
  <div id="app">
    <!-- <Header></Header> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Header from './components/Header.vue'

export default {
  name: "App",
  components: {
    // Header
  },
};
</script>

<style>
#app {
  /* background-color: aliceblue; */
}
</style>
