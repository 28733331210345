import Vue from "vue";
import VueRouter from "vue-router";

// const MainLayout = () => import("@/components/HelloWorld.vue");
const Lotto = () => import("@/views/Lotto.vue");
const LottoHistory = () => import("@/views/LottoHistory.vue");
// const BallTable = () => import("@/views/BallTable.vue");
// const PageNotFound = () => import("@/views/PageNotFound.vue");
// import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "MainLayout",
  //   component: MainLayout,
  //   // meta: { notAuth: true },
  // },
  {
    path: "/",
    name: "Lotto",
    component: Lotto,
  },
  {
    path: "/history/:id",
    name: "LottoHistory",
    component: LottoHistory,
  },
  // {
  //   path: "/table",
  //   name: "BallTable",
  //   component: BallTable,
  // },
  // { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (store.getters.Auth.loggedIn) {
//       next();
//       return;
//     }
//     next("/login");
//   } else {
//     next();
//   }
// });

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.notAuth)) {
//     if (!store.getters.Auth.loggedIn) {
//       next();
//       return;
//     }
//     next("/");
//   } else {
//     next();
//   }
// });

export default router;
